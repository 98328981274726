import api, { AuthClient } from "../../../api/api";

export const getBillboard = (name) => AuthClient().get(`/v2/billboard/${name}`);

export const getOperationCatalogue = async (billboard) => {
  const response = await api.request({
    url: `/v1/chat-files?billboard=${billboard}`,
    method: "GET",
  });
  return response;
};
