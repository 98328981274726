import moment from "moment";
import api from "./api";
import { FCM } from "@capacitor-community/fcm";
import { requestForToken } from "../utils/firebase";
import { toast } from "react-toastify";
import axios from "axios";
import * as cookie from "cookie";

export const getIPAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    console.log("<getIPAddress> res", response);
    return response?.data?.ip;
  } catch (error) {
    toast.error("IP Protocol Error");
  }
};
export const signIn = async (data) => {
  const ip = await getIPAddress();
  const response = await api.request({
    url: "/v1/login",
    method: "POST",
    data: { ...data, ip },
  });

  return response;
};

export const forget = async (data) => {
  const response = await api.request({
    url: "/v1/forgotPassword",
    method: "POST",
    data,
  });

  return response;
};

export const verifyLogin = async (data) => {
  const ip = await getIPAddress();
  const response = await api.request({
    url: "/v1/verify-login",
    method: "POST",
    data: { ...data, ip },
  });
  if (response.remote === "success") {
    console.log("<verify-login>,reponse", response.data);
    localStorage.setItem("token", response.data.token);

    // Set the cookie with attributes
    document.cookie = cookie.serialize("jwt", response.data.token, {
      maxAge: 7 * 24 * 60 * 60, // 7 days in seconds
      path: "/", // Cookie accessible site-wide
      secure: true, // Use HTTPS in production
      sameSite: "strict", // Prevent CSRF attacks
    });

    window.location.replace("/");
  }

  return response;
};

export const resendOtp = async (data) => {
  const response = await api.request({
    url: "/v1/resend-otp",
    method: "POST",
    data,
  });

  return response;
};

export const logOut = async () => {
  let deviceId = null;
  try {
    deviceId = await FCM.getToken();
  } catch {
    try {
      deviceId = await requestForToken();
    } catch {}
  }

  const response = await api.request({
    url: "/v1/logout",
    method: "POST",
    data: {
      deviceId,
    },
  });
  localStorage.removeItem("token");

  return response;
};

export const forceLogOut = async () => {
  let deviceId = null;
  try {
    deviceId = await FCM.getToken();
  } catch {
    try {
      deviceId = await requestForToken();
    } catch {}
  }

  const response = await api.request({
    url: "/v1/force-Alllogout",
    method: "POST",
    data: {
      deviceId,
    },
  });
  localStorage.removeItem("token");

  return response;
};

export const CreateUser = async (data) => {
  const response = await api.request({
    url: "/v1/create-user",
    method: "POST",
    data,
  });
  return response;
};

export const Alluser = async (limit, page, sortBy, search, role, country) => {
  const response = await api.request({
    url: `/v1/admin/alluser?limit=${limit}&page=${page}&sort=${sortBy}&${
      role ? "role=" + role : ""
    }${search ? "&searchFields=" + search : ""}${
      country ? "&country=" + country : ""
    }`,
    method: "GET",
  });
  if (response.remote === "success") response.data.data.totalResults = 100;
  //
  return response;
};

export const GetUser = async (id) => {
  const response = await api.request({
    url: `/v1/admin/getuser/${id}`,
    method: "GET",
  });

  return response;
};

export const GetSelf = async () => {
  const response = await api.request({
    url: `/v1/getself/`,
    method: "GET",
  });

  return response;
};

export const GetSelfUpdate = async (data) => {
  const response = await api.request({
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `/v1/updateMe/`,
      method: "PATCH",
      data,
    },
  });
  return response;
};

export const GetSelfUpdatePassword = async (data) => {
  const response = await api.request({
    url: `/v1/update-password/`,
    method: "PATCH",
    data,
  });
  return response;
};

export const PasswordReset = async (data, id) => {
  const response = await api.request({
    url: `/v1/resetPassword/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetallBillboard = async (sort = "", page = "", limit = "") => {
  const response = await api.request({
    url: `v1/billboard/all-billboard?sort=${sort}&page=${page}&limit=${limit}`,
    method: "GET",
  });
  return response;
};

export const GetAllCameraBillboard = async () => {
  const response = await api.request({
    url: `v1/billboard/all-camera-billboard`,
    method: "GET",
  });
  return response;
};

export const GetLogsApi = async (name, date49DaysAgo, yesterdayDate) => {
  const response = await api.request({
    url: `v1/automation/check/${name}?fetchDate=${date49DaysAgo}&endDate=${yesterdayDate}`,
    method: "GET",
  });
  return response;
};

export const GetBillboardApi = async (id) => {
  const response = await api.request({
    url: `v1/billboard/${id}`,
    method: "GET",
  });
  return response;
};
export const GetBillboardByName = async (name) => {
  const response = await api.request({
    url: `v1/billboard/name?billboard=${name}`,
    method: "GET",
  });
  return response;
};

export const DeleteRemark = async (id) => {
  const response = await api.request({
    url: `v1/billboard/delete-remark/${id}`,
    method: "DELETE",
  });
  return response;
};

export const GetReportBillsOffline = async (names) => {
  const response = await api.request({
    url: `v1/billboard/multiple-bill-offline`,
    method: "POST",
    data: {
      billboards: names,
    },
  });
  return response;
};

export const RegisterGuestUser = async (number) => {
  const response = await api.request({
    url: `v1/create-guest-user`,
    method: "POST",
    data: {
      phone: number,
      role: "GuestUser",
    },
  });
  return response;
};

export const VerifyOtp = async (data) => {
  const response = await api.request({
    url: `v1/verify-otp`,
    method: "PATCH",
    data,
  });
  return response;
};

export const FreezeUser = async (id, data) => {
  const response = await api.request({
    url: `/v1/freeze-user/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const UpdateBillboardByName = async (data, name) => {
  const response = await api.request({
    url: `v1/billboard/update-billboard?billboard=${name}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const Updatebillboard = async (data, id) => {
  const response = await api.request({
    url: `/v1/billboard/update-billboard/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const UpdatebillboardConnectivity = async (data, name) => {
  const response = await api.request({
    url: `/v2/billboard/update-connectivity/${name}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetUpdateUser = async (data, id) => {
  const response = await api.request({
    url: `/v1/updateuser/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetUserAds = async (id) => {
  const response = await api.request({
    url: `/v2/ad-guestUser/${id}`,
    method: "GET",
  });
  return response;
};

export const GetCountries = async () => {
  const response = await api.request({
    url: `/v1/getCountries`,
    method: "GET",
  });
  return response;
};

export const GetAllSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/summary`,
    method: "GET",
  });
  return response;
};

export const GetSummaryByDate = async (date, skip, name) => {
  const response = await api.request({
    url: `/v1/c1/summary?summary_created=${date}&&skip=${skip}&&name=${name}`,
    method: "GET",
  });
  return response;
};

export const GetBillboardAds = async (name, status) => {
  const response = await api.request({
    url: `/v2/ad-guestUser?billboard=${name}&status=${status}`,
    method: "GET",
  });
  return response;
};

export const LastScan = async () => {
  const response = await api.request({
    url: `/v1/logs/last-scan`,
    method: "GET",
  });
  return response;
};

export const GetLastScan = async () => {
  const response = await api.request({
    url: `/v1/c1/summary`,
    method: "GET",
  });
  return moment(response.data.documents.data.summary_created).format(
    "DD MMM YY, HH:mm:ss"
  );
};

export const GetReportBillOffline = async (billboard) => {
  const response = await api.request({
    url: `/v1/billboard/report-offline/${billboard}`,
    method: "GET",
  });
  return response;
};

export const CameraStatus = async () => {
  const response = await api.request({
    url: `/v1/c1/camerastatus`,
    method: "GET",
  });
  return response;
};

export const RealSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/realtime-summary`,
    method: "GET",
  });
  return response;
};

// Master Summary
export const MasterSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/master-summary`,
    method: "GET",
  });
  return response;
};

export const SummaryDev = async () => {
  const response = await api.request({
    url: `/v1/c1/summary-dev`,
    method: "GET",
  });
  return response;
};

export const OfflineBillboards = async (live = false) => {
  const response = await api.request({
    url: `/v1/billboard/offline?live=${live}`,
    method: "GET",
  });
  return response;
};

export const StatisticReport = async (type = "today") => {
  const response = await api.request({
    url: `/v1/c1/stat-report?type=${type}`,
    method: "GET",
  });
  return response;
};

export const GetRushHour = async () => {
  const response = await api.request({
    url: `/v1/c1/rush-hour`,
    method: "GET",
  });
  return response;
};

export const DeleteUser = async (id) => {
  const response = await api.request({
    url: `/v1/delete-user/${id}`,
    method: "DELETE",
  });
  return response;
};

export const CreateSmtp = async (data) => {
  const response = await api.request({
    url: "/v1/smptp/create-smptp",
    method: "POST",
    data,
  });

  return response;
};

export const getSMTP = () => {
  return api.request({
    url: "/v1/smptp/smptp",
    method: "GET",
  });
};

export const AdminSms = async (body) => {
  const response = await api.request({
    url: `v1//send-sms`,
    method: "POST",
    data: {
      userId: body.userId,
      message: body.message,
    },
  });
  return response;
};

//
