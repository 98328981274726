import { AuthClient } from "../../../../api/api";

export const getAllEmployess = () => {
  return AuthClient().get(
    `/v1/admin/alluser?role=Employee&fields=_id,fullname,email,photo`
  );
};

export const getAllCustomers = (customer) => {
  return AuthClient().get(
    `/v1/admin/alluser?role=${customer}&fields=_id,fullname,email,photo,phone,role,billboards`
  );
};

export const createInvoice = (data) => {
  return AuthClient().post(`/v2/invoice`, data);
};

export const getAllInvoices = (
  page,
  status,
  sort,
  type,
  search,
  billboard,
  employee,
  customer,
  date_between
) => {
  return AuthClient().get(
    `/v2/invoice?limit=${15}&page=${page}&status=${status}&sort=${
      sort || "-createdAt"
    }&type=${type}${billboard ? "&billboard=" + billboard : ""}${
      search ? "&search=" + search : ""
    }${employee ? "&employee=" + employee : ""}${
      customer ? "&customer=" + customer : ""
    }${date_between ? "&date_between=" + date_between : ""}`
  );
};

export const deleteInvoice = (id) => {
  return AuthClient().delete(`/v2/invoice/${id}`);
};

export const getInvoice = (id) => {
  return AuthClient().get(`/v2/invoice/${id}`);
};

export const updateInvoice = (id, body) => {
  return AuthClient().patch(`/v2/invoice/${id}`, body);
};
