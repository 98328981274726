import Alert from "./alert.svg"
import Warning from "./warning.svg"
import Search from "./search.svg"
import Line from "./line.svg"
const SVG = {
  Alert,
  Warning,
  Line,
  Search
}
export default SVG
