import api from "../../api/api";

export const getAllOperationCatalogue = async (limit, page, search, sortBy) => {
  const response = await api.request({
    url: `/v1/chat-files?limit=${limit}&page=${page}${
      search ? "&search=" + search : ""
    }${sortBy ? "&sort=" + sortBy : ""}`,
    method: "GET",
  });
  return response;
};
