import React, { useEffect, useState } from "react";
import Sidebar from "../settings/sidebar";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import Pdfs from "../../images/pdfs.png";
import Users from "../../images/users.png";
import Search from "../../images/searchtag.png";
import Funnel from "../../images/funnel.png";
import { getAllOperationCatalogue } from "./Operation-catalogue.api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Loader from "../loader";

export default function OperationCatalogue() {
  const [catalogueData, setCatalogueData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [searchText, setSearchText] = useState("");

  const backendHost = process.env.REACT_APP_BACKEND_HOST;
  //   const backendHost = "https://staging.shoobill.me:3006";

  const getAllCatalogue = async () => {
    setLoading(true);
    const limit = 18;
    getAllOperationCatalogue(limit, page, debouncedSearch)
      .then(async ({ data }) => {
        if (data.status === "success") {
          setTotalPages(Math.ceil(data.total / limit));
          const formattedData = data.data.map((item) => {
            console.log("\n[OperationCatalogue] item", item);

            return {
              ticketId: item.ticket[0]?._id?.slice(-5) || "N/A",
              billboardName: item.ticket[0]?.billboard || "N/A",
              creationDate:
                moment(item?.createdAt).format("DD MMM YYYY, HH:MM") || "N/A",
              img: `${backendHost}/${item.message}` || Pdfs,
            };
          });
          console.log("\n[OperationCatalogue] formattedData", formattedData);
          setCatalogueData(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching OperationCatalogueS:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Debounce the search logic
    setTimeout(() => {
      setDebouncedSearch(searchText);
    }, 300); // Adjust debounce delay if needed
  }, [searchText]);

  useEffect(() => {
    getAllCatalogue();
  }, [page, debouncedSearch]);

  const handleSetPage = (data) => {
    let nextPage = data.selected + 1;
    setPage(nextPage);
  };

  //   const catalogueData = [
  //     {
  //       ticketId: "5a0b1",
  //       billboardName: "shoof4",
  //       creationDate: "10 Jun 2023, 09:29",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "7c3d2",
  //       billboardName: "shoof5",
  //       creationDate: "12 Jul 2023, 11:45",
  //       img: Users,
  //     },
  //     {
  //       ticketId: "9f5e3",
  //       billboardName: "shoof6",
  //       creationDate: "15 Aug 2023, 14:30",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "5a0b1",
  //       billboardName: "shoof4",
  //       creationDate: "10 Jun 2023, 09:29",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "7c3d2",
  //       billboardName: "shoof5",
  //       creationDate: "12 Jul 2023, 11:45",
  //       img: Users,
  //     },
  //     {
  //       ticketId: "9f5e3",
  //       billboardName: "shoof6",
  //       creationDate: "15 Aug 2023, 14:30",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "5a0b1",
  //       billboardName: "shoof4",
  //       creationDate: "10 Jun 2023, 09:29",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "7c3d2",
  //       billboardName: "shoof5",
  //       creationDate: "12 Jul 2023, 11:45",
  //       img: Users,
  //     },
  //     {
  //       ticketId: "9f5e3",
  //       billboardName: "shoof6",
  //       creationDate: "15 Aug 2023, 14:30",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "5a0b1",
  //       billboardName: "shoof4",
  //       creationDate: "10 Jun 2023, 09:29",
  //       img: Pdfs,
  //     },
  //     {
  //       ticketId: "7c3d2",
  //       billboardName: "shoof5",
  //       creationDate: "12 Jul 2023, 11:45",
  //       img: Users,
  //     },
  //     {
  //       ticketId: "9f5e3",
  //       billboardName: "shoof6",
  //       creationDate: "15 Aug 2023, 14:30",
  //       img: Pdfs,
  //     },
  //   ];
  return (
    <div className="my-3">
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Card
          variant="outlined"
          className="card-content"
          sx={{ minHeight: "100%" }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Sidebar />
              <Grid item lg={10} xs={12}>
                <Card
                  variant="outlined"
                  sx={{ minHeight: "calc((100vw/2 - 307px) * 1.34)" }}
                >
                  <CardContent>
                    <Box sx={{ padding: "20px" }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <Box className="searchInputs">
                          <img src={Search} alt="" />
                          <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            sx={{ flex: 1, maxWidth: "300px", marginRight: 2 }}
                            onClick={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        </Box>
                        <Button className="filterBtn">
                          <img src={Funnel} alt="" />
                          Filter
                        </Button>
                      </Box>

                      {/* Grid of Cards */}
                      {loading ? (
                        <Loader />
                      ) : (
                        <Grid container spacing={2}>
                          {catalogueData.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                              <Box className="catalogueCard">
                                <img src={item.img} alt="PDF icon" />
                                <Box>
                                  <ul>
                                    <li>
                                      <span>Ticket Id:</span> {item.ticketId}
                                    </li>
                                    <li>
                                      <span>Billboard Name:</span>{" "}
                                      {item.billboardName}
                                    </li>
                                    <li>
                                      <span>Creation date:</span>{" "}
                                      {item.creationDate}
                                    </li>
                                  </ul>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      <ReactPaginate
                        previousLabel="<<"
                        nextLabel=">>"
                        pageRangeDisplayed={10}
                        pageCount={totalPages}
                        forcePage={page - 1}
                        onPageChange={handleSetPage}
                        activeClassName={"active"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
